<template>
  <div class="errorPages">
    <div class="errorPages-content">
      <h1 class="errorPages-title">404</h1>
      <div class="errorPages-subtitle">PAGE NOT FOUND</div>
      <div class="errorPages-text">
        Oops! You're lost. The page you are looking for was not found.
      </div>
      <div class="errorPages-btn">
        <button class="btn btn-outline-primary " @click.prevent="goBackHandler">
          GO BACK
        </button>
        <button class="btn btn-outline-primary " @click.prevent="goHomeHandler">
          HOME PAGE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    goBackHandler() {
      this.$router.back()
    },
    goHomeHandler() {
      this.$router.push('/connections')
    }
  }
}
</script>
